/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343a50;
  --card-background-color: #51576d;
  --card-background-lighter-color: #7a7f92;
  --main-text-color: #f7f6f4;
  --title-text-color: #3cba8b;
}

body {
  background-color: var(--main-background-color);
}

h2,
p {
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

.MuiFab-extended {
  height: auto;
  padding-right: 0 !important;
}

.MuiFab-label {
  font-weight: bold;
}

.MuiButton-containedPrimary:not(.MuiButton-fullWidth) {
  border-radius: 24px !important;
  padding: 6px 16px;
}

.MuiButton-label {
  font-size: 1.2em;
}

.MuiLinearProgress-colorPrimary {
  background-color: var(--main-text-color) !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: var(--title-text-color) !important;
}
